import { AppConfig } from '@ifhms/feedlot/web/core/config';
import { APP_CONFIG_BASE } from './app-config-base';

export const APP_CONFIG_DEV: AppConfig = {
  ...APP_CONFIG_BASE,

  environmentName: 'local',
  production: false,
  apiUrl: 'http://host.docker.internal:4500/api',
  signalRUrl: 'http://host.docker.internal:5227/PushNotification',
  showDevFeature: true,
  authServerUrl: 'http://host.docker.internal:46000',
  feedlotApiUrl: 'http://host.docker.internal:5227/api',
  upcSignalRUrl: 'http://localhost:3210',
  adminAppUrl: 'http://host.docker.internal:4656',
  chatAiUrl: 'http://host.docker.internal:4650'
};
