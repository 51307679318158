import { APP_CONFIG_BASE } from './app-config-base';
import { AppConfig } from '@ifhms/feedlot/web/core/config';

export const APP_CONFIG_FMS_QA: AppConfig = {
  ...APP_CONFIG_BASE,

  environmentName: 'qa',
  production: false,
  apiUrl: 'https://admin-api.qa.fms.feedlothealth.com/api',
  signalRUrl: 'https://feedlot-api.qa.fms.feedlothealth.com/PushNotification',
  showDevFeature: true,
  authServerUrl: 'https://identity.qa.fms.feedlothealth.com',
  feedlotApiUrl: 'https://feedlot-api.qa.fms.feedlothealth.com/api',
  upcSignalRUrl: 'http://localhost:3210',
  adminAppUrl: 'https://admin.qa.fms.feedlothealth.com',
  chatAiUrl: 'https://chat.qa.fms.feedlothealth.com/'
};
