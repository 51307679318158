import { AppConfig } from '@ifhms/feedlot/web/core/config';
import { APP_CONFIG_BASE } from './app-config-base';

export const APP_CONFIG_FMS_DEV: AppConfig = {
  ...APP_CONFIG_BASE,

  environmentName: 'dev',
  production: false,
  apiUrl: 'https://admin-api.dev.fms.feedlothealth.com/api',
  signalRUrl: 'https://feedlot-api.dev.fms.feedlothealth.com/PushNotification',
  showDevFeature: true,
  authServerUrl: 'https://identity.dev.fms.feedlothealth.com',
  feedlotApiUrl: 'https://feedlot-api.dev.fms.feedlothealth.com/api',
  upcSignalRUrl: 'http://localhost:3210',
  adminAppUrl: 'https://admin.dev.fms.feedlothealth.com',
  chatAiUrl: 'https://chat.dev.fms.feedlothealth.com/'
};
