import { APP_CONFIG_BASE } from './app-config-base';
import { AppConfig } from '@ifhms/feedlot/web/core/config';

export const APP_CONFIG_FMS_PRODUCTION: AppConfig = {
  ...APP_CONFIG_BASE,

  environmentName: 'production',
  production: false,
  apiUrl: 'https://admin-api.frm.feedlothealth.com/api',
  signalRUrl: 'https://feedlot-api.frm.feedlothealth.com/PushNotification',
  showDevFeature: false,
  authServerUrl: 'https://identity.frm.feedlothealth.com',
  feedlotApiUrl: 'https://feedlot-api.frm.feedlothealth.com/api',
  upcSignalRUrl: 'http://localhost:3210',
  adminAppUrl: 'https://admin.frm.feedlothealth.com',
  integrations: {
    heapAppId: '1551651232',
    sentryDsn:
      'https://45f06374990f91aa6ef08b93a42e89c3@o4506538482532352.ingest.us.sentry.io/4507426076688384'
  },
  chatAiUrl: 'https://chat.frm.feedlothealth.com/'
};
