import { APP_CONFIG_BASE } from './app-config-base';
import { AppConfig } from '@ifhms/feedlot/web/core/config';

export const APP_CONFIG_FMS_UAT: AppConfig = {
  ...APP_CONFIG_BASE,

  environmentName: 'uat',
  production: false,
  apiUrl: 'https://admin-api.uat.fms.feedlothealth.com/api',
  signalRUrl: 'https://feedlot-api.uat.fms.feedlothealth.com/PushNotification',
  showDevFeature: true,
  authServerUrl: 'https://identity.uat.fms.feedlothealth.com',
  feedlotApiUrl: 'https://feedlot-api.uat.fms.feedlothealth.com/api',
  upcSignalRUrl: 'http://localhost:3210',
  adminAppUrl: 'https://admin.uat.fms.feedlothealth.com',
  chatAiUrl: 'https://chat.uat.fms.feedlothealth.com/'
};
